import { cacheLife } from "@/cache-life";
import env from "@/env.mjs";
import { DepictOptions } from "@/lib/depict/types";
import { DepictSort } from "@/lib/hooks/useProductFilter/types";
import { GetListingResponse } from "@depict-ai/types/api/GetListingResponse";

import { cache as dedupe } from "react";

type NextSearchParams = Record<string, string | string[] | undefined>;

export const availableSortOrders = {
  _relevance_desc: {
    field: "_relevance",
    order: "desc",
    key: "_relevance_desc",
  },
  sale_price_asc: {
    field: "sale_price",
    order: "asc",
    key: "sale_price_asc",
  },
  sale_price_desc: {
    field: "sale_price",
    order: "desc",
    key: "sale_price_desc",
  },
  product_timestamp_desc: {
    field: "product_timestamp",
    order: "desc",
    key: "product_timestamp_desc",
  },
};

type SortKey = keyof typeof availableSortOrders;

export const getDepictMarketFromPricelistId = (
  priceListId: string | undefined,
) => {
  if (!priceListId) return "no";
  return priceListId === "21" ? "no" : "us";
};

export function getSortOrders<T extends SortKey[]>(keys: T) {
  return keys.map((key) => availableSortOrders[key as SortKey]);
}

export function getSortOrderFromParam(key?: string | string[] | undefined) {
  if (typeof key !== "string") return;
  const order = availableSortOrders[key as SortKey];
  return order
    ? {
        field: order.field,
        order: order.order,
      }
    : undefined;
}

export const fetchDepictCategory = dedupe(async (options: DepictOptions) => {
  if (!options.category) return null;

  const res = await fetch(
    `${env.NEXT_PUBLIC_DEPICT_API_URL}/v3/listings/${options.category}?merchant=camillapihl&market=${options.market}&locale=${options.locale}`,
    {
      next: {
        revalidate: cacheLife.FIVE_MINUTES,
      },
    },
  );

  if (!res.ok) return null;

  const json = (await res.json()) as GetListingResponse;

  return json;
});

export const SORT_QUERY_KEY = "sort";

type FilterData = {
  data: string[] | string;
  field: string;
  op: "in";
};

export function getFiltersFromParams(
  searchParams: NextSearchParams,
  filterKeys: string[] | undefined,
) {
  if (!filterKeys) return [];

  const filters: { [key: string]: FilterData } = {};

  for (const key of filterKeys) {
    const paramValue = searchParams[key];

    if (!paramValue) continue;

    const values = Array.isArray(paramValue) ? paramValue : [paramValue];

    // If the key already exists, merge the data arrays
    if (filters[key]) {
      filters[key].data = [...filters[key].data, ...values];
    } else {
      filters[key] = {
        data: values,
        field: key,
        op: "in",
      };
    }
  }

  return Object.values(filters);
}

export interface ServerFilterOptions {
  filterKeys?: string[];
}

export type ServerFilterReturn = {
  filterValues: FilterData[];
  sort?: DepictSort | undefined;
};

export function getServerFilters(
  searchParams: NextSearchParams,
  { filterKeys }: ServerFilterOptions,
) {
  const filterValues = getFiltersFromParams(searchParams, filterKeys);
  const sortOrder = getSortOrderFromParam(searchParams.sort);

  return {
    sort: sortOrder
      ? {
          ...sortOrder,
        }
      : undefined,
    filterValues,
  } as ServerFilterReturn;
}
